import PropTypes from 'prop-types'
import {
  DefaultLoadingSpinner,
  useQueryResult,
} from 'components/generic/query-result-handler'
import arrayHasData from 'lib/arrayHasData'
import { Divider, Section } from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import { dark } from 'themes'
import Padded, { PaddedStack } from 'components/generic/padded'
import trackingIds from 'tracking/tracking-ids'
import AD_TARGETING_KEYS from 'enums/ad-targeting-keys'
import dynamic from 'next/dynamic'
import { useAdTargetingDispatch } from 'hooks/useAdTargeting'
import Tracking from 'scripts/tracking'
import Throw500 from 'components/generic/throw-500'
import Throw404 from 'components/generic/throw-404'
import ENTITY_TYPE from 'enums/entity-type'
import useUpcomingEvents from 'hooks/useUpcomingEvents'
import Listing from 'components/shared/listing'
import useMetadataOverrides from 'hooks/useMetadataOverrides'

import Seo from 'components/generic/seo/Seo'
import PromoterDetailSubNav from '../PromoterDetailSubNav'
import GET_PROMOTER_DETAIL from './GetPromoterDetailQuery'
import PromoterDetailBar from '../promoter-detail-bar'
import PromoterUpcomingEvents from './promoter-upcoming-events'
import PromoterNews from './promoter-news'
import PromoterDescription from './promoter-description'
import PromoterEventsArchive from './promoter-events-archive'
import extractPromoterTrackingCodes from './extractPromoterTrackingCodes'
import PromoterDetailPageTemplate from '../PromoterDetailPageTemplate'
import { usePromoterSEO, PROMOTER_PAGE_TYPE } from '../promoterSeoUtils'

const PromoterRelatedContent = dynamic(
  () => import('./promoter-related-content'),
  { ssr: false }
)

const PromoterOverviewTab = ({ id }) => {
  return (
    <PromoterDetailPageTemplate id={id}>
      <Section data-tracking-id={trackingIds.promoterDetail}>
        <PromoterOverviewTabQuery id={id} />
      </Section>
    </PromoterDetailPageTemplate>
  )
}

PromoterOverviewTab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const PromoterOverviewTabQuery = ({ id }) => {
  const { data, loading, error, empty } = useQueryResult(GET_PROMOTER_DETAIL, {
    variables: {
      id,
    },
    dataKey: 'promoter',
  })
  const {
    events: upcomingEvents,
    loading: upcomingEventsLoading,
    error: upcomingEventsError,
  } = useUpcomingEvents({ type: Listing.Aggregations.Promoter, id })

  const metadataOverrides = useMetadataOverrides()

  if (error || upcomingEventsError) {
    return <Throw500 />
  }

  if (loading || upcomingEventsLoading) {
    return <DefaultLoadingSpinner />
  }

  if (empty) {
    return <Throw404 entityType={ENTITY_TYPE.Promoter} />
  }

  return (
    <PromoterOverviewTabMarkup
      data={data}
      upcomingEvents={upcomingEvents}
      metadataOverrides={metadataOverrides}
    />
  )
}

PromoterOverviewTabQuery.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const PromoterOverviewTabMarkup = ({
  data,
  upcomingEvents,
  metadataOverrides,
}) => {
  useAdTargetingDispatch(buildAdTargeting(data.id, data.contentUrl))
  Tracking.useTracking({
    gtm: extractPromoterTrackingCodes(data),
    mixpanel: {},
  })

  const seoData = usePromoterSEO({
    data,
    overrides: {
      title: metadataOverrides?.title,
      description: metadataOverrides?.description,
    },
    pageType: PROMOTER_PAGE_TYPE.overview,
  })

  return (
    <>
      <Seo {...seoData} />
      <ThemeProvider theme={dark}>
        <PromoterDetailSubNav
          contentUrl={data.contentUrl}
          hasUpcomingEvents={arrayHasData(upcomingEvents)}
        />
        <Divider />
        <PromoterDetailBar {...data} links={buildLinks(data)} />
        {arrayHasData(upcomingEvents) && (
          <Padded>
            <PromoterUpcomingEvents events={upcomingEvents} />
          </Padded>
        )}
      </ThemeProvider>
      <PaddedStack>
        {data.blurb && (
          <PromoterDescription
            promoter={data.name}
            about={data.blurb}
            imageUrl={data.logoUrl}
          />
        )}
        <PromoterNews id={data.id} />
      </PaddedStack>
      <Padded>
        <PromoterEventsArchive id={data.id} />
      </Padded>
      <PromoterRelatedContent area={data.area} />
    </>
  )
}

PromoterOverviewTabMarkup.propTypes = {
  data: PropTypes.object.isRequired,
  upcomingEvents: PropTypes.array,
  metadataOverrides: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
}

const buildLinks = (promoter) => {
  const websiteAndEmail = [
    {
      type: 'Website',
      href: promoter.website,
    },
  ]

  const socialMediaLinks =
    promoter.socialMediaLinks?.map(({ platform, link }) => {
      const type = platform.charAt(0) + platform.slice(1).toLowerCase()
      return {
        type,
        href: link,
      }
    }) ?? []

  return [...socialMediaLinks, ...websiteAndEmail]
}

const buildAdTargeting = (id, path) => [
  [AD_TARGETING_KEYS.PAGE, path],
  [AD_TARGETING_KEYS.CATEGORY, 'Listings'],
  [AD_TARGETING_KEYS.SECTION, 'Promoters'],
  [AD_TARGETING_KEYS.PROMOTER, id.toString()],
]

export { PromoterOverviewTabMarkup, PromoterOverviewTabQuery }
export default PromoterOverviewTab
